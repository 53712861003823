import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { FaCertificate } from "react-icons/fa";

export const Banner = ({product, Icon, color, type}) => {

    const sections = product?.description?.split("<section>");
    const subSections = sections && sections[0].split("<subsection>");
    let sale = false
    if(product.supRrp > 0 && product.priceVat < product.supRrp) {
      sale = Math.floor(100-((product.priceVat*100)/product.supRrp));
    }
 
    let style
    switch(color) {
      case 'green':
        style = "text-green-600 border-green-600 font-bold "
        break;
      case 'orange':
        style = "text-orange-600 border-orange-600 font-bold "
        break;
      case 'gray':
        style = "text-gray-400 border-gray-200"
        break;
      default:
        // code block
    } 
    return (
      <>
      <Link to={'/plysaci/detail/' + product.alias + '/'} >
       
        <div className="grid md:grid-cols-2 border rounded-md lg:space-x-4 my-5 relative md:bg-red-700 overflow-hidden " >
          <svg
            className=" w-full opacity-10 hidden md:block absolute" 
            fill="none"
            viewBox="0 0 500 500"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width="10" height="10"
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-white" fill="currentColor" />
              </pattern>
            </defs>
            <rect className="h-full w-full" x={0} y={0} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <div className={" mr-1 w-auto   border-transparent p-10 text-white relative hidden md:inline " + (style)}>
            
              <div className="text-xl font-bold flex items-center space-x-2 uppercase"><FaCertificate className="w-5 h-5 opacity-70" /><span>{type}</span></div> 
              <div className="text-4xl font-bold md:mb-10">{product.name}</div> 
              
              <div className="grid grid-cols-2 lg:grid-cols-1 mb-3">
                <div className="grid  text-xs items-center mt-1">
                    <p className=" text-xs ">{subSections && subSections[0]} {product.size && product.size + ' cm'}</p>
                    <div className="flex">

                    <p className=" text-base font-bold">{product.priceVat.toLocaleString()} Kč</p>
                    {sale && <p className="ml-3 line-through  ">{product.supRrp.toLocaleString()} Kč</p>}
                    
                    </div>
                </div> 
                <div className="flex justify-end lg:justify-start">
                  <div  className="hidden md:inline-flex  w-auto lg:mt-6 lg:space-x-4 items-center h-10 px-4 py-2 text-gray-100 bg-gray-700 rounded-md border border-gray-800   hover:text-white hover:bg-gray-800 focus:outline-none ">
                      Prohlédnout
                  </div>

                </div>
              </div>
              <svg
                  className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-red-700 transform translate-x-1/2 z-10  -mr-5 "
                  fill="currentColor"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                  aria-hidden="true"
                >
                  <polygon points="50,0 100,0 50,100 0,100" />
              </svg>
             
              <svg version="1.1" id="Layer_1"   viewBox="0 0 771.447 1417.32" className="hidden lg:block absolute uppercase right-10 inset-y-0 h-full w-48 text-red-700 transform translate-x-1/2 z-20  -mr-5 "
                  fill="currentColor" >
                   
                  <g>
                      <rect x="-82.684" y="1198.464" transform="matrix(0.2683 -0.9633 0.9633 0.2683 -1122.4801 1025.4473)" fill="none" width="393.015" height="106.402"/>
                      <text transform="matrix(0.2683 -0.9633 0.9633 0.2683 59.0884 1440.4072)"   fontSize="72">{type}</text>
                      <rect x="5.221" y="878.392" transform="matrix(0.2683 -0.9633 0.9633 0.2683 -749.8295 875.9415)" fill="none" width="393.017" height="106.402"/>
                      <text transform="matrix(0.2683 -0.9633 0.9633 0.2683 146.9932 1120.334)"  fontSize="72">{type}</text>
                      <rect x="93.021" y="564.565" transform="matrix(0.2683 -0.9633 0.9633 0.2683 -383.2725 730.9053)" fill="none" width="393.016" height="106.402"/>
                      <text transform="matrix(0.2683 -0.9633 0.9633 0.2683 234.792 806.5078)"  fontSize="72">{type}</text>
                      <rect x="182.025" y="243.602" transform="matrix(0.2683 -0.9633 0.9633 0.2683 -8.9594 581.8113)" fill="none" width="393.016" height="106.403"/>
                      <text transform="matrix(0.2683 -0.9633 0.9633 0.2683 323.7974 485.5454)"  fontSize="72">{type}</text>
                      <rect x="269.799" y="-70.679" transform="matrix(0.2683 -0.9633 0.9633 0.2683 358.0166 436.417)" fill="none" width="393.015" height="106.403"/>
                      <text transform="matrix(0.2683 -0.9633 0.9633 0.2683 411.5703 171.2646)" fontSize="72">{type}</text>
                  </g>
                  </svg>
                  <svg version="1.1" id="Layer_1"   viewBox="0 0 771.447 1417.32" className="hidden lg:block absolute uppercase right-10 inset-y-0 h-full w-48 text-white transform translate-x-1/2 z-10  -mr-4 "
                    fill="currentColor" >
                    <polygon  points="393.023,1417.32 0,1417.32 378.424,0 771.447,0 "/>
                </svg>
                 <svg version="1.1" id="Layer_1"   viewBox="0 0 771.447 1417.32" className="hidden lg:block absolute uppercase right-0 inset-y-0 h-full w-48  text-red-700 transform translate-x-1/2 z-10 -mr-2  opacity-70 "
                  fill="currentColor" >
                  <polygon  points="393.023,1417.32 0,1417.32 378.424,0 771.447,0 "/>
                  <g>
                      <rect x="-82.684" y="1198.464" transform="matrix(0.2683 -0.9633 0.9633 0.2683 -1122.4801 1025.4473)" fill="none" width="393.015" height="106.402"/>
                      <text transform="matrix(0.2683 -0.9633 0.9633 0.2683 59.0884 1440.4072)" fill="#F6F6F6" fontSize="62">{type}</text>
                      <rect x="5.221" y="878.392" transform="matrix(0.2683 -0.9633 0.9633 0.2683 -749.8295 875.9415)" fill="none" width="393.017" height="106.402"/>
                      <text transform="matrix(0.2683 -0.9633 0.9633 0.2683 146.9932 1120.334)" fill="#F6F6F6" fontSize="62">{type}</text>
                      <rect x="93.021" y="564.565" transform="matrix(0.2683 -0.9633 0.9633 0.2683 -383.2725 730.9053)" fill="none" width="393.016" height="106.402"/>
                      <text transform="matrix(0.2683 -0.9633 0.9633 0.2683 234.792 806.5078)" fill="#F6F6F6" fontSize="62">{type}</text>
                      <rect x="182.025" y="243.602" transform="matrix(0.2683 -0.9633 0.9633 0.2683 -8.9594 581.8113)" fill="none" width="393.016" height="106.403"/>
                      <text transform="matrix(0.2683 -0.9633 0.9633 0.2683 323.7974 485.5454)" fill="#F6F6F6"  fontSize="62">{type}</text>
                      <rect x="269.799" y="-70.679" transform="matrix(0.2683 -0.9633 0.9633 0.2683 358.0166 436.417)" fill="none" width="393.015" height="106.403"/>
                      <text transform="matrix(0.2683 -0.9633 0.9633 0.2683 411.5703 171.2646)" fill="#F6F6F6"  fontSize="62">{type}</text>
                  </g>
                  </svg>
                  
                  <svg version="1.1" id="Layer_1"   viewBox="0 0 700 50" className="block lg:hidden absolute uppercase left-0 bottom-0  w-full h-10   text-red-700  z-10  opacity-70 "
                  fill="currentColor" > 
                  <g>
                      
                      <text x={0} y={40}  width="150" fill="#F6F6F6" fontSize="32">{type}</text>
                     
                      <text x={170} y={40}  width="150"  fill="#F6F6F6" fontSize="32">{type}</text> 
                      <text x={340} y={40} width="150"  fill="#F6F6F6" fontSize="32">{type}</text> 
                      <text x={510} y={40} width="150" fill="#F6F6F6"  fontSize="32">{type}</text> 
                      <text x={680} y={40} width="150"  fill="#F6F6F6"  fontSize="32">{type}</text>
                  </g>
                  </svg>
          </div>
               
          <div className="relative overflow-hidden rounded-md">
            
                <div className=" rounded-md  overflow-hidden md:h-full  lg:aspect-[16/9] bg-white  flex justify-center items-center  ">
                    <GatsbyImage className="rounded-md  md:scale-75 object-contain  lg:object-cover "
                      image={product.imgData?.childImageSharp?.gatsbyImageData}
                      alt={product.name}
                    />
                </div>
               
                
                <div className="absolute top-0 right-0 left-0 bottom-0  bg-black bg-opacity-5 w-full h-full group-hover:bg-opacity-10" />
                <div className="absolute top-1/2 right-0 left-0 bottom-0 bg-gradient-to-b from-transparent  to-black opacity-70 w-full md:hidden " />

                <div className="absolute left-0 bottom-0  right-0 p-5 text-white md:hidden">
                  <div className="grid grid-cols-2 lg:grid-cols-1">
                    <div className="grid  text-xs items-center mt-1">
                        <div className="text-2xl font-bold">{product.name}</div> 
                        <div className="flex">

                        <p className=" text-base font-bold">{product.priceVat.toLocaleString()} Kč</p>
                        {sale && <p className="ml-3 line-through  ">{product.supRrp.toLocaleString()} Kč</p>}
                        
                        </div>
                    </div> 
                  </div>
                </div>
          
          </div>
          
          <FaCertificate className="w-9 h-9  top-2 right-2  text-white z-10 absolute"  />
          <FaCertificate className="w-8 h-8  top-2 right-2 mt-0.5 mr-0.5  text-red-700 z-10 absolute"  />
         
        </div>
        </Link>
      </>
    )
  }