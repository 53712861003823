import React, { useContext } from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet"; 
import ProductsNew from "../components/goods/ProductsNew";
import { Banner } from "../components/elements/Banner";
import { Feature } from "../components/elements/Feature";

import PageContext from "../context/PageContext";
import { graphql } from 'gatsby'
import {AiOutlineSafetyCertificate as History, AiOutlinePercentage} from "react-icons/ai"
import {default as Speed} from "../icons/speed.svg"
import { TopProducts } from "../components/elements/TopProducts";

const IndexPage = ({location, data}) => {

  const [context] = useContext(PageContext);
  const searchString = context?.searchString;

  let bannerItem = data?.scomos?.goodFindOne
  bannerItem = {
    ...bannerItem,
    imgData: data?.allProduct?.nodes[0]?.localFile
  }
 
  let query = 'goods/items/';
  let page = 1; 

  let path
  let cat
  path = location.pathname.split('/');
  if(path[2]!=='strana' && path[2]!=='detail') {
      cat = path[2]
  }
  
  if(path[2]==='strana') {
      page = parseInt(path[3])
  }
  if(path[3]==='strana') {
      page = parseInt(path[4])
  }

  let orderBy = [];
  orderBy.push('updated:DESC')

  let params = [];
  params.push('priceVat:>:0') 

  return(
  <>
      <Helmet>
      <meta charSet="utf-8" />
      <title>Plyšovník</title> 
    </Helmet>
      <Layout location={location}> 
        {!searchString && 
          <div className="bg-gray-200 py-3 -mt-5 ">
                <div className=" max-w-7xl mx-auto p-5 py-2">
                    <div className="  rounded-md bg-gray-600 shadow-md">
                        <Banner product={bannerItem} type={'Novinka'} />
                    </div>
                </div>
                <div className=" max-w-7xl mx-auto p-5 py-2 ">
                    <div className="border rounded-md bg-white grid md:grid-cols-3 divide-y md:divide-x md:divide-y-0 shadow-md">
                        
                        <Feature text={'Sleva 100 Kč při nákupu nad 2000 Kč'} Icon={AiOutlinePercentage} />
                        <Feature text={'Expedujeme tentýž pracovní den'} Icon={Speed} />
                        <Feature text={'Garance 30 dnů na vrácení peněz'} Icon={History} />
                    </div>
                </div>
          </div>
        
        }
        {!searchString &&         
          <TopProducts />
        }

          <ProductsNew location={location} paging={16} query={query}  page={page} cat={cat} params={params} orderBy={orderBy}  />
             
    </Layout>
  </>
  )}

export const query = graphql`
  query HomePageQuery {
    allProduct(filter: {productId: {in: ["5625"]}}) {
      nodes {
        id
        productId
        name
        localFile{
         childImageSharp {
            gatsbyImageData
         }
       }
      }
  },
  scomos {
        goodFindOne(id: "5625") {
            id
            name
            description
            alias
            size
            priceVat
            supRrp
        }, 
    }
  }
`
export default IndexPage
